import apiClient from '../../apis/apiClient'
import { dangerToast, successToast } from '../../helpers/toasts'
import EventApi from '../../apis/EventApi'

export const namespaced = true
export const state = {
    vue: null,
    plans: {},

    id: null,
    stripe_id: null,
    stripe_price: null,
    stripe_cycle: null,
    stripe_status: null,
    name: null,
    on_grace_period: null,
    current_period_end: null,

    is_loading: false,
    cancellation_reason: '',
    one_click_content: {},
    scheduled_plan_category: '',
    one_click_upgrade: {
        plan: null,
        subscriptionCycle: null,
        success_feedback: '',
        renewal_msg: '',
    },
    plan: null,
    on_trial: null,
    special_plans: [],
    can_cancel: false,
    has_other_site_subscription: null,
    plan_category: null,
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },
    setOneClickUpgrade(state, obj) {
        Object.assign(state.one_click_upgrade, obj)
    },
}

export const actions = {
    cardlessTrial({ commit, dispatch, store, rootState }, { plan }) {
        return apiClient
            .post('/account/user/' + rootState.user.user.id + '/cardlessTrial', {
                plan: plan,
            })
            .then((response) => {
                localStorage.removeItem('user.subscription')
                return dispatch('getSubscription')
            })
    },
    setValue({ commit }, value) {
        commit('SET_VALUE', value)
    },
    async getSubscription({ commit, dispatch, state }) {
        commit('SET_VALUE', { is_loading: true })
        try {
            const response = await apiClient.get('/account/user/subscription')
            commit('SET_VALUE', response.data)
            localStorage.setItem('user.subscription', JSON.stringify(response.data))
            if (response.data.plan) {
                await dispatch('user/setSubscriptionPlan', response.data.plan, { root: true })
            }
            await dispatch('user/getPayments', null, { root: true })
            await dispatch('getOneClickContent', window.brand || 'worksheet')
        } catch (error) {
            state.vue?.$bvToast.toast('There was an error obtaining your subscription information.', dangerToast)
        } finally {
            commit('SET_VALUE', { is_loading: false })
        }

        // The previous implementation of this function rendered the below code unreachable, so I've commented it out.
        // TODO: Determine if this code should be uncommented and if so, what the correct implementation should be.
        // try {
        //     commit('SET_VALUE', JSON.parse(localStorage.getItem('user.subscription')))
        // } catch (error) {
        //     localStorage.removeItem('user.subscription')
        //     dispatch('getSubscription')
        // }
    },
    getAvailablePlans({ commit, dispatch, rootState }, state) {
        if (!localStorage.getItem('user.plans')) {
            commit('SET_VALUE', { is_loading: true })
            return apiClient
                .get('/user/' + rootState.user.user.id + '/plans')
                .then((response) => {
                    commit('SET_VALUE', { plans: response.data })
                    commit('SET_VALUE', { is_loading: false })
                    localStorage.setItem('user.plans', JSON.stringify(response.data))
                })
                .catch((error) => {
                    commit('SET_VALUE', { is_loading: false })
                    state.vue?.$bvToast.toast('There was an error obtaining subscription details.', dangerToast)
                })
        }

        try {
            commit('SET_VALUE', { plans: JSON.parse(localStorage.getItem('user.plans')) })
        } catch (error) {
            localStorage.removeItem('user.plans')
            dispatch('getAvailablePlans')
        }
    },
    changeFrequency({ commit, dispatch, state }, payload) {
        return apiClient
            .put('/subscription/' + state.id + '/change-plan', payload)
            .then((response) => {
                //hide the make printable modal if open
                state.vue?.$bvModal.hide('printable-modal')

                localStorage.removeItem('user.subscription')
                return dispatch('getSubscription')
            })
            .catch((error) => {
                commit('SET_VALUE', { is_loading: false })
                state.vue?.$bvToast.toast('There was an error updating your subscription.', dangerToast)
                throw error
            })
    },
    subscribe({ commit, dispatch, store, rootState }, { method, plan, trial }) {
        return apiClient
            .post('/account/user/' + rootState.user.user.id + '/subscribe', {
                method: method, //rootState.cards.intent_response.setupIntent.payment_method,
                plan: plan,
                trial: trial,
            })
            .then((response) => {
                localStorage.removeItem('user.subscription')
                return dispatch('getSubscription')
            })
    },
    purchase({ commit, dispatch, store, rootState }, { method, price, document }) {
        return apiClient
            .post('/account/user/' + rootState.user.user.id + '/purchase', { method, price, document })
            .then((response) => {
                localStorage.removeItem('user.subscription')
                return dispatch('getSubscription')
            })
    },
    cancelSubscription({ commit, state, dispatch }) {
        commit('SET_VALUE', { is_loading: true })
        return apiClient
            .delete('subscription/' + state.id)
            .then((response) => {
                EventApi.create_event('subscription-cancelled', state.cancellation_reason)
                //clear out the cancellation reason
                dispatch('setValue', { cancellation_reason: '' })
                localStorage.removeItem('user.subscription')
                return dispatch('getSubscription')
            })
            .catch((error) => {
                commit('SET_VALUE', { is_loading: false })
                state.vue?.$bvToast.toast(error.message, dangerToast)
                throw error
            })
    },
    async resumeSubscription({ commit, state, dispatch }) {
        commit('SET_VALUE', { is_loading: true })
        try {
            const response = await apiClient.put('subscription/' + state.id + '/resume')
            localStorage.removeItem('user.subscription')
            state.vue?.$bvToast.toast('Your subscription has been resumed', successToast)
            return dispatch('getSubscription')
        } catch (error) {
            state.vue?.$bvToast.toast(error.message, dangerToast)
        } finally {
            commit('SET_VALUE', { is_loading: false })
        }
    },
    async getOneClickContent({ commit, dispatch, state }, page = null) {
        commit('SET_VALUE', { is_loading: true })
        try {
            const response = await apiClient.get('user/my-profile-content', { params: { entity: page } })
            commit('SET_VALUE', response.data)
        } catch (error) {
            state.vue?.$bvToast.toast('There was an error obtaining your subscription information.', dangerToast)
        } finally {
            commit('SET_VALUE', { is_loading: false })
        }
    },
    setOneClickUpgrade({ commit }, value) {
        commit('setOneClickUpgrade', value)
    },
    runSubscriptionShim({ commit, dispatch, state }) {
        commit('SET_VALUE', { is_loading: true })
        return apiClient
            .get('/account/user/subscription-shim')
            .then((response) => {
                return dispatch('getSubscription')
            })
            .catch((error) => {
                commit('SET_VALUE', { is_loading: false })
                state.vue?.$bvToast.toast(
                    'There was an error obtaining your reciprocal subscription information.',
                    dangerToast,
                )
            })
    },
}

export const getters = {
    hasSubscription: (state) => {
        return state.id ? true : false
    },
    hasPlans: (state) => {
        return Object.keys(state.plans).length ? true : false
    },
    oneClickContent: (state) => {
        return state.one_click_content
    },
    oneClickUpgrade: (state) => {
        return state.one_click_upgrade
    },
    planCategory: (state) => state.plan_category,
}
