interface CSSCustomProperties {
    [key: string]: string
}

interface ScrollbarConfig {
    SCROLLBAR_WIDTH: number
    getCSSCustomProperties: () => CSSCustomProperties
    updateCSSVariables: () => void
}

const scrollbarConfig: ScrollbarConfig = {
    SCROLLBAR_WIDTH: 12,

    getCSSCustomProperties(): CSSCustomProperties {
        return {
            '--scrollbar-width': `${this.SCROLLBAR_WIDTH}px`,
        }
    },

    updateCSSVariables(): void {
        const cssProperties = this.getCSSCustomProperties()
        Object.entries(cssProperties).forEach(([property, value]) => {
            document.documentElement.style.setProperty(property, value)
        })
    },
}

// Export the config object and its type
export default scrollbarConfig
export type { ScrollbarConfig, CSSCustomProperties }
